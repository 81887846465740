<template>
  <div class="content">
    <h1 class="title title--big title--color title--indent">Контакты</h1>
    <div class="contacts">
      <h2 class="title title--indent">Контактная информация</h2>
      <div class="contacts__top">
        <div class="contacts__part">
          <div class="contacts__cols">
            <div class="contacts__col">
              <div v-if="main_contacts.phone" class="contacts__row">
                <img src="@/assets/img/sidebar/phone-icon.svg" alt="Phone icon">
                <a href="tel:main_contacts.phone">{{ main_contacts.phone }}</a>
              </div>
              <div v-if="main_contacts.email" class="contacts__row">
                <img src="@/assets/img/sidebar/link-icon.svg" alt="At icon">
                <a href="mailto:main_contacts.email">{{ main_contacts.email }}</a>
              </div>
              <div v-if="main_contacts.site" class="contacts__row">
                <img src="@/assets/img/web-icon.svg" alt="WWW icon">
                <a href="main_contacts.site" target="_blank">{{ main_contacts.site }}</a>
              </div>
            </div>
            <div class="contacts__col">
              <div v-if="main_contacts.whatsapp" class="contacts__row">
                <img src="@/assets/img/wa-icon.svg" alt="Whatsapp icon">
                <a :href="`https://wa.me/${main_contacts.whatsapp}`" target="_blank">{{ main_contacts.whatsapp }}</a>
              </div>
              <div v-if="main_contacts.telegram" class="contacts__row">
                <img src="@/assets/img/sidebar/tg-blue.svg" alt="Telegram icon">
                <a :href="`https://t.me/${main_contacts.telegram}`" target="_blank">{{ main_contacts.telegram }}</a>
              </div>
              <div v-if="main_contacts.full_address" class="contacts__row">
                <img src="@/assets/img/place-icon.svg" alt="Place icon">
                <span>{{ main_contacts.full_address }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="contacts__part">
<!--          <iframe frameborder="no" style="box-sizing: border-box;" width="100%" height="200" src="http://widgets.2gis.com/widget?type=firmsonmap&amp;options=%7B%22pos%22%3A%7B%22lat%22%3A12.757411%2C%22lon%22%3A37.631505%2C%22zoom%22%3A16%7D%2C%22opt%22%3A%7B%22city%22%3A%22moscow%22%7D%2C%22org%22%3A%224504127908850354%22%7D"></iframe>-->
        </div>
      </div>
      <h2 class="title title--indent">Дополнительные контакты</h2>
      <VsaList class="vsa-custom">
        <VsaItem v-for="contact in additional" :key="contact.id">
          <VsaHeading>
            {{ contact.name }}
          </VsaHeading>
          <VsaContent>
            <div class="contact-cards">
              <div v-for="department in contact.contacts" :key="department.id" class="contact-cards__item">
                <div class="contact-cards__avatar">
                  <img :src="department.crop_url" alt="Contact person">
                </div>
                <div class="contact-cards__info">
                  <div class="contact-cards__name">{{ department.lastname }} {{ department.firstname }} {{ department.patronymic }}</div>
                  <div class="contact-cards__post">{{ department.post }}</div>
                  <div class="contact-cards__contacts">
                    <div v-if="department.phone" class="contact-cards__contact">
                      <img src="@/assets/img/sidebar/phone-icon.svg" alt="Phone icon">
                      <a :href="`tel:${department.phone}`">{{ department.phone }}</a>
                    </div>
                    <div v-if="department.email" class="contact-cards__contact">
                      <img src="@/assets/img/sidebar/link-icon.svg" alt="At icon">
                      <a :href="`mailto:${department.email}`">{{ department.email }}</a>
                    </div>
                    <div v-if="department.telegram" class="contact-cards__contact">
                      <img src="@/assets/img/sidebar/tg-blue.svg" alt="Telegram icon">
                      <a :href="`https://t.me/${department.telegram}`" target="_blank">{{ department.telegram }}</a>
                    </div>
                    <div v-if="department.whatsapp" class="contact-cards__contact">
                      <img src="@/assets/img/wa-icon.svg" alt="Whatsapp icon">
                      <a :href="`https://wa.me/${department.whatsapp}`" target="_blank">{{ department.whatsapp }}</a>
                    </div>
                    <div v-if="department.vk" class="contact-cards__contact">
                      <img src="@/assets/img/vk-icon.svg" alt="VK icon">
                      <a :href="department.vk" target="_blank">{{ department.vk }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </VsaContent>
        </VsaItem>
      </VsaList>
    </div>
  </div>
</template>

<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

export default {
  name: 'Contacts',
  components: {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
  },
  data() {
    return {
      main_contacts: {},
      additional: [],
    };
  },
  mounted() {
    this.fetchMain();
    this.fetchAdditional();
  },
  methods: {
    fetchMain() {
      this.$store.dispatch('partner/contacts/GET_MAIN')
        .then(response => {
          [this.main_contacts] = response.data;
        })
        .catch(response => {
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
    fetchAdditional() {
      this.$store.dispatch('partner/contacts/GET_ADDITIONAL')
        .then(response => {
          this.additional = response.data;
        })
        .catch(response => {
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
  }
};
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.contacts__top
  display: flex
  justify-content: space-between
  margin-bottom: 30px

  +max-w($mobile_xl)
    flex-direction: column

.contacts__part
  width: 44%

  +max-w($desktop_sm)
    width: 49%

  +max-w($mobile_xl)
    width: 100%

.contacts__cols
  display: flex
  justify-content: space-between

  +max-w($laptop_sm)
    flex-direction: column

.contacts__col
  width: 50%

  +max-w($laptop_sm)
    width: 100%

  &:not(:last-child)
    +max-w($laptop_sm)
      margin-bottom: 20px

.contacts__row
  display: flex
  align-items: center
  margin-bottom: 20px
  font-size: 16px

  &:last-child
    margin-bottom: 0

  img
    flex-shrink: 0
    margin-right: 20px

  a
    text-decoration: none
    color: $color-text-base
    transition: color 0.3s
    overflow-wrap: anywhere

    &:hover
      color: $color-theme
</style>